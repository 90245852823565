import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggle_popup } from "redux/actions/main"
import ModalCont from "./ModalCont"
import { CopyToClipboard } from "react-copy-to-clipboard"

const Shared = () => {
	const dispatch = useDispatch()
	const { result, selected_stamp, login } = useSelector((state) => state.main)
	const { stamps } = login

	return (
		<ModalCont tag="shared" size="medium">
			<div className="modal__shared">
				<div className="title">
					<span className="main">TOKEN SHARED</span>
					<span className="sub">Kirim kode token ini ke temanmu</span>
				</div>
				<div className="content">
					<div className="code">
						<div className="label">
							<span>{result.shared_code}</span>
						</div>
						<CopyToClipboard text={result.shared_code}>
							<button className="btn btn-copy">Copy</button>
						</CopyToClipboard>
					</div>
					<div className="stamp">
						<div
							className="img"
							style={{
								backgroundImage: `url(${stamps?.find((e) => e.stamp_id === selected_stamp)?.stamp_image})`,
							}}
						/>
						<span className="name">{stamps?.find((e) => e.stamp_id === selected_stamp)?.stamp_name}</span>
					</div>
				</div>
				<div className="button-wrapper">
					<button className="btn btn-confirm" onClick={() => dispatch(toggle_popup(false))}>
						Konfirmasi
					</button>
				</div>
			</div>
		</ModalCont>
	)
}

export default Shared
