import "react-app-polyfill/ie9"
import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import * as defaults from "helper/default"
// import * as serviceWorker from "./serviceWorker" // UNCOMMENT FOR PWA

// COMPONENT & OTHER
import "assets/scss/index.scss"
import Main from "base/Main"

// REDUX
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import rootReducer from "redux/reducers"

// REDUX PERSIST
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"

// SET REDUX STORE
const store = createStore(rootReducer, applyMiddleware(thunk))
const persistor = persistStore(store)

// SET DEFAULTS
defaults.setAnalytics()
defaults.setAxios()
defaults.setSentry()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			{/* <React.StrictMode> */}
			<Main />
			{/* </React.StrictMode> */}
		</PersistGate>
	</Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// serviceWorker.unregister(); // UNCOMMENT TO REMOVE PWA || COMMENT THE REGISTER
// serviceWorker.register() // UNCOMMENT FOR PWA
