import React from "react"
import ModalCont from "./ModalCont"
import { useDispatch, useSelector } from "react-redux"
import { toggle_popup } from "redux/actions/main"

const Congratulations = () => {
	const dispatch = useDispatch()
	const { popup_context, result } = useSelector((state) => state.main)

	const subtext = {
		token: "",
		exchange: "Item akan di kirim ke vault dalam waktu max 24 jam",
	}

	const confirmButton = () => {
		if (popup_context === "exchange") {
			// const claimed = Object.values(login?.rewards)[selectedExchangeIndex].filter((e) => e.is_claimed)
			dispatch(toggle_popup(false))
			// if (claimed.length > 1) {
			// } else {
			// 	dispatch(toggle_popup("unlocked"))
			// }
		} else if (popup_context === "mission") {
			dispatch(toggle_popup("mission"))
		} else {
			dispatch(toggle_popup(false))
		}
	}

	return (
		<ModalCont tag="congratulations" size="small">
			<div className="modal__congratulations">
				<div className="title">
					<span className="main">SELAMAT</span>
					<span className="sub">{subtext[popup_context]}</span>
				</div>
				<div className="content">
					{popup_context === "mission" ? (
						<span className="token">
							<div className="token-img" /> {result.obtained_token}
						</span>
					) : (
						<>
							<div className={`img ${popup_context}`}>
								<div
									style={{
										backgroundImage: `url(${result.stamp_image || result.item_image})`,
									}}
								/>
							</div>
							<span className="name">{result.stamp_name || result.item_name}</span>
						</>
					)}
				</div>
				<div className="button-wrapper">
					<button className="btn btn-confirm" onClick={() => confirmButton()}>
						Konfirmasi
					</button>
				</div>
			</div>
		</ModalCont>
	)
}

export default Congratulations
