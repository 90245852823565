import axios from "axios"
import { catch_error, login, toggle_loader } from "./main"
import { throttle } from "./throttle"
import {
	err_msg,
	getUrlParam,
	removeParamUrl,
	// fetchCss
} from "helper"

const ls = window.localStorage

export const set_param = (data) => ({
	type: "SET_PARAM",
	data,
})

export const set_callback = (data) => ({
	type: "SET_CALLBACK",
	data,
})

export const set_token = () => {
	return (dispatch, getState) => {
		const { auth } = getState()

		const param = {
			access_token: getUrlParam("access_token") || "",
			account_id: getUrlParam("account_id") || "",
			userId: getUrlParam("userId") || "",
			region: getUrlParam("region") || "SG",
			nickname: getUrlParam("nickname") || "",
			lang: getUrlParam("lang") || "en",
		}

		// UNCOMMENT FOR CUSTOM CSS
		// fetchCss(param.lang, param.region);

		if (!param.access_token && !auth.param.access_token) {
			// Toggle popup or toast not logged in
			dispatch(catch_error("NOT_LOGIN"))
		} else {
			if (param.access_token) {
				dispatch(set_param(param))
				dispatch(get_jwt())
			} else {
				// Call API get check_login or info
				dispatch(login(true))
			}

			// Set attribute data-lang in body
			document.body.setAttribute("data-lang", param.lang)
		}
	}
}

export const set_ls_token = () => {
	return (dispatch) => {
		var token = getUrlParam("token")
		dispatch(toggle_loader(true))
		// dispatch(get_translate())

		if (getUrlParam("err")) {
			let error = getUrlParam("err")
			dispatch(catch_error(error))
			removeParamUrl()
		} else {
			if (token) {
				ls.setItem("token", token)
				window.location.href = process.env.REACT_APP_BASE_URL
			} else {
				// CALL API check login
				// dispatch(main.login())
			}
		}
	}
}

export const get_jwt = (throttling = false) => {
	return (dispatch, getState) => {
		// Throttling /oauth/callback/ 43200000s/12 hours
		if (dispatch(throttle("jwt_token", 43200000)) && throttling) {
			window.location.href = `${process.env.REACT_APP_BASE_URL}`
			return
		}

		const { auth } = getState()

		axios
			.get(`oauth/callback/?access_token=${auth.param.access_token}&account_id=${auth.param.account_id}`)
			.then((resp) => {
				ls.setItem("token", resp.data.token)
				axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = resp.data.token
				removeParamUrl()
				dispatch(login(true))
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
	}
}
