import React from "react"
import AnimatedPage from "helper/AnimatedPage"
import { useDispatch, useSelector } from "react-redux"
import { put_data, toggle_popup } from "redux/actions/main"
import "animate.css"

const Exchange = () => {
	const dispatch = useDispatch()
	const { rewards, stamps } = useSelector((state) => state.main?.login)
	const rewardsList = rewards && Object.values(rewards)
	const banner = ["a", "b", "c", "d", "e"]
	// const [arrow, setArrow] = useState(false)

	// const ref = useRef()
	// const measureBox = (e) => {
	// 	const targetBox = e.target
	// 	const scrollLeft = targetBox.scrollLeft
	// 	const clientWidth = targetBox.clientWidth
	// 	const scrollWidth = targetBox.scrollWidth
	// 	if (Math.round(scrollWidth - (clientWidth + scrollLeft)) <= 1) {
	// 		setArrow(false)
	// 	} else {
	// 		setArrow(true)
	// 	}
	// }

	// useEffect(() => {
	// 	const targetBox = ref.current
	// 	if (targetBox && Math.floor(targetBox.scrollWidth - targetBox.clientWidth) > 0) {
	// 		setArrow(true)
	// 	} else {
	// 		setArrow(false)
	// 	}
	// }, [])

	return (
		<AnimatedPage>
			<div
				className="page__exchange"
				// onAnimationEnd={() => {
				// 	const targetBox = ref.current
				// 	if (targetBox && Math.floor(targetBox.scrollWidth - targetBox.clientWidth) > 0) {
				// 		setArrow(true)
				// 	} else {
				// 		setArrow(false)
				// 	}
				// }}
			>
				<div>
					<ul className="exchange-list">
						{rewardsList?.map((data, index) => (
							<li
								key={index}
								style={{
									backgroundImage: `url(${process.env.REACT_APP_CDN}images/img_exchange_jkt_${banner[index]}.png)`,
									animationDelay: `${index / 10}s`,
								}}
								className={`animate__animated animate__fadeInRightBig`}
							>
								{data
									.filter((e) => !e.is_claimed)
									.map((data, index) => (
										<div key={index} className={`prize-box ${data.can_claim ? "active" : ""}`}>
											<div className="name">
												<span>{data.item_name}</span>
											</div>
											<div className="details">
												<div
													className="image"
													style={{
														backgroundImage: `url(${data.item_img})`,
													}}
												/>
												<div className="claim">
													<div className="cost">
														<div className="material">
															<div
																className="material-img"
																style={{ backgroundImage: `url(${data.stamp_img})` }}
															/>
															<div className="material-qty">
																<span>
																	{stamps?.find((e) => e.stamp_id === data.stamp_id).owned}/{data.stamp_cost}
																</span>
															</div>
														</div>
														{data.second_stamp_id && (
															<div className="material multiples">
																<div
																	className="material-img"
																	style={{ backgroundImage: `url(${data.second_stamp_img})` }}
																/>
																<div className="material-qty">
																	<span>
																		{stamps?.find((e) => e.stamp_id === data.second_stamp_id).owned}/
																		{data.second_stamp_cost}
																	</span>
																</div>
															</div>
														)}
													</div>
													<button
														className="btn btn-claim"
														onClick={() => {
															dispatch(
																put_data(
																	"selectedExchangeIndex",
																	rewardsList.findIndex((e) => e.find((d) => d.reward_id === data.reward_id))
																)
															)
															dispatch(put_data("selected_reward", data.reward_id))
															dispatch(toggle_popup("confirmation", "exchange"))
														}}
													>
														claim
													</button>
												</div>
											</div>
										</div>
									))}
							</li>
						))}
					</ul>
				</div>

				{/* <div className={`arrow ${arrow ? "show" : "hidden"}`}>
					<div />
				</div> */}
			</div>
		</AnimatedPage>
	)
}

export default Exchange
