import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { claim_mission, toggle_popup } from "redux/actions/main"
import ModalCont from "./ModalCont"

const Mission = () => {
	const dispatch = useDispatch()
	const { missions } = useSelector((state) => state.main.login)
	const { lang_json, disabled } = useSelector((state) => state.main)

	return (
		<ModalCont tag="mission" size="big">
			<button className="btn btn-close" onClick={() => dispatch(toggle_popup(false))} />
			<div className="modal__mission">
				<div className="mission-header">
					<span>DAILY MISSION</span>
					<div className="logo" />
				</div>
				<div className="content">
					<span>{lang_json.txt_mission_info}</span>
					<ul>
						{missions?.map((data, index) => (
							<li key={index}>
								<span>{data.mission_name}</span>
								<button
									className={`btn btn-claim ${data.can_claim && !disabled ? "active" : "disabled"}`}
									onClick={() => dispatch(claim_mission(data.mission_id))}
								>
									{data.is_claimed ? (
										"claimed"
									) : (
										<>
											<div className="token-ico" /> {data.token_num}
										</>
									)}
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
		</ModalCont>
	)
}

export default Mission
