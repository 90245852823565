import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

class Loader extends React.Component {
	render() {
		const { loader } = this.props;
		return (
			<div className={`overlay ${loader ? "active" : ``}`}>
				{loader && (
					<img src="https://cdngarenanow-a.akamaihd.net/gstaticid/Support/puff.svg" alt="" className="img" />
				)}
			</div>
		);
	}
}

Loader.defaultProps = {
	loader: false
};

Loader.propTypes = {
	loader: PropTypes.bool
};
export default Loader;
