import axios from "axios"
import { err_msg, getLsObject } from "helper"

const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false,
})

const common = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/tr_common/`,
	withCredentials: false,
})

export const toggle_popup = (tipe, context = false) => ({
	type: "TOGGLE_POPUP",
	tipe,
	context,
})

export const toggle_loader = (data) => ({
	type: "TOGGLE_LOADER",
	data: data,
})

export const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data,
})

/**
 *
 * Example of how to call API
 *
 */
export const login = () => {
	return (dispatch) => {
		// UNCOMMENT & ADD DEFAULT LANG AS PARAM ex: 'EN'
		// dispatch(get_translate('EN'))
		// dispatch(toggle_loader(true))

		axios
			.get("api/info")
			.then((resp) => {
				dispatch(put_data("login", resp.data.data))
				!getLsObject("not_first_login") && dispatch(toggle_popup("intro"))
				dispatch(history())
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
		// .then(() => {
		// 	dispatch(toggle_loader(false))
		// })
	}
}

export const claim_mission = (mission_id) => {
	return (dispatch) => {
		dispatch(put_data("disabled", true))

		axios
			.post("api/claim_mission", { mission_id })
			.then((resp) => {
				const data = resp.data.data
				dispatch(put_data("result", data))
				dispatch(toggle_popup("congratulations", "mission"))
				dispatch(login())
			})
			.catch((err) => {
				dispatch(catch_error(err.code))
			})
			.then(() => {
				dispatch(put_data("disabled", false))
			})
	}
}

export const spin = () => {
	return (dispatch) => {
		axios
			.post("api/spin")
			.then((resp) => {
				if (resp.data.status === "success") {
					const data = resp.data.data
					dispatch(put_data("spin", true))
					dispatch(put_data("result", data))

					setTimeout(() => {
						dispatch(login())
					}, 2000)
				}
			})
			.catch((err) => {
				dispatch(catch_error(err.code))
				dispatch(put_data("spin", false))
			})
	}
}

export const share_stamp = (stamp_id) => {
	return (dispatch) => {
		axios
			.post("api/share_stamp", { stamp_id })
			.then((resp) => {
				const data = resp.data.data
				dispatch(put_data("result", data))
				dispatch(toggle_popup("shared"))
				dispatch(login())
			})
			.catch((err) => {
				dispatch(catch_error(err.code))
			})
	}
}

export const claim_stamp = (shared_code) => {
	return (dispatch) => {
		axios
			.post("api/claim_stamp", { shared_code })
			.then((resp) => {
				const data = resp.data.data
				dispatch(put_data("result", data))
				dispatch(toggle_popup("congratulations", "token"))
				dispatch(login())
			})
			.catch((err) => {
				dispatch(put_data("err_msg", err.code))
			})
	}
}

export const claim_exchange = (reward_id) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.post("api/claim_exchange", { reward_id })
			.then((resp) => {
				const data = resp.data.data
				dispatch(put_data("result", data))
				dispatch(toggle_popup("congratulations", "exchange"))
				dispatch(login())
			})
			.catch((err) => {
				dispatch(catch_error(err.code))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const history = () => {
	return (dispatch) => {
		axios.get("api/history").then((resp) => {
			const data = resp.data.data
			dispatch(put_data("history", data.history))
		})
	}
}

/**
 *
 * Example of how to implement multi language
 *
 */
export const get_translate = (default_lang) => {
	return (dispatch) => {
		let language = getLsObject("param")?.lang.toUpperCase() || default_lang

		const main_localize = cdn.get(`data.json?${Math.random() * 10000}`)
		const common_localize = common.get(`${language}.json?${Math.floor(Math.random() * 10000)}`)

		axios
			.all([main_localize, common_localize])
			.then((resp) => {
				const json = {
					...resp[0].data,
					...resp[1].data,
				}
				dispatch(put_data("lang_json", json))
			})
			.catch((err) => {})
	}
}

/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg) => {
	return (dispatch) => {
		if (err_msg) {
			dispatch(put_data("err_msg", err_msg))
			dispatch(toggle_popup("error"))
		}

		if (err_msg === "invalid_region") {
			localStorage.clear()
		}
	}
}
