import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { claim_exchange, spin, toggle_popup } from "redux/actions/main"
import ModalCont from "./ModalCont"

const Confirmation = () => {
	const dispatch = useDispatch()
	const { popup_context, selected_reward, login, lang_json } = useSelector((state) => state.main)

	const confirmationContent = () => {
		if (popup_context === "spin") {
			return <span>{lang_json.txt_use_token_to_spin}</span>
		} else if (popup_context === "exchange") {
			const reward =
				login &&
				Object.values(login?.rewards)
					.map((dt) => dt.find((e) => e.reward_id === selected_reward))
					.find((e) => e)

			return (
				<>
					<div className="img">
						<div style={{ backgroundImage: `url(${reward?.item_img})` }} />
					</div>
					<span className="name">{reward?.item_name}</span>
				</>
			)
		}
	}

	const confirmButton = () => {
		if (popup_context === "spin") {
			dispatch(spin())
		} else if (popup_context === "exchange") {
			dispatch(claim_exchange(selected_reward))
		}
	}

	return (
		<ModalCont tag="confirmation" size="small">
			<div className="modal__confirmation">
				<div className="title">
					<span className="main">{lang_json.txt_confirmation}</span>
					{popup_context === "exchange" && <span className="sub">Tukar token dengan item dibawah ini?</span>}
				</div>
				<div className="content">{confirmationContent()}</div>
				<div className="button-wrapper">
					<button className="btn btn-back" onClick={() => dispatch(toggle_popup(false))}>
						Kembali
					</button>
					<button className="btn btn-confirm" onClick={() => confirmButton()}>
						Konfirmasi
					</button>
				</div>
			</div>
		</ModalCont>
	)
}

export default Confirmation
