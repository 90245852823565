import { setLsObject } from "helper"
import React from "react"
import { useDispatch } from "react-redux"
import { toggle_popup } from "redux/actions/main"
import ModalCont from "./ModalCont"

const Intro = () => {
	const dispatch = useDispatch()

	return (
		<ModalCont tag="intro">
			<div
				className="modal__intro"
				onClick={() => {
					dispatch(toggle_popup(false))
					setLsObject("not_first_login", true)
				}}
			/>
		</ModalCont>
	)
}

export default Intro
