import React from "react"
import { useDispatch } from "react-redux"
import { toggle_popup } from "redux/actions/main"

const HistoryInfo = () => {
	const dispatch = useDispatch()

	return (
		<div className="container">
			<button className="btn btn-info" onClick={() => dispatch(toggle_popup("info"))}>
				<div className="icon" />
			</button>
			<button className="btn btn-history" onClick={() => dispatch(toggle_popup("history"))}>
				<div className="icon" />
			</button>
		</div>
	)
}

export default HistoryInfo
