export const set_throttle = (key, data) => ({
	type: "SET_THROTTLE",
	key,
	data
});

export const throttle = (key, interval) => {
	return (dispatch, getState) => {
		const now = new Date();
		const lastThrottling = getState().throttle[key] || 0;

		const timeDiff = now.getTime() - lastThrottling;

		if (timeDiff < interval) {
			return true;
		} else {
			dispatch(set_throttle(key, now.getTime()));
			return false;
		}
	};
};
