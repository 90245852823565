import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { claim_stamp, put_data, share_stamp, toggle_popup } from "redux/actions/main"
import ModalCont from "./ModalCont"
import { useForm } from "react-hook-form"

const Share = () => {
	const dispatch = useDispatch()
	const { register, handleSubmit, setValue } = useForm()
	const [selectedToken, setSelectedToken] = useState(0)
	const { stamps } = useSelector((state) => state.main.login)
	const { err_msg, popup_type, lang_json } = useSelector((state) => state.main)

	const submitClaim = (data) => {
		dispatch(claim_stamp(data.code))
	}

	useEffect(() => {
		if (popup_type === "share") {
			setValue("code", "")
			dispatch(put_data("err_msg", false))
		}
	}, [popup_type, dispatch, setValue])

	useEffect(() => {
		const initialStamp = stamps?.find((e) => e.owned > 1)?.stamp_id
		setSelectedToken(initialStamp)
	}, [stamps])

	const confirmShare = () => {
		dispatch(put_data("selected_stamp", selectedToken))
		dispatch(share_stamp(selectedToken))
	}

	return (
		<ModalCont tag="share" size="big">
			<button className="btn btn-close" onClick={() => dispatch(toggle_popup(false))} />
			<div className="modal__share">
				<div className="title">
					<span className="main">SHARE TOKEN</span>
					<span className="sub">Klik 1 token yang akan kamu share ke temanmu</span>
				</div>
				<div className="content">
					<ul className="token-list">
						{stamps
							?.filter((e) => e.owned > 1)
							.map((data, index) => (
								<li
									key={index}
									className={selectedToken === data.stamp_id ? "active" : ""}
									onClick={() => setSelectedToken(data.stamp_id)}
								>
									<div className="token-count">{data.owned}</div>
									<div className="token-img" style={{ backgroundImage: `url(${data.stamp_image})` }} />
								</li>
							))}
					</ul>
					<button className="btn btn-confirm" onClick={() => stamps?.find((e) => e.owned > 1)?.stamp_id && confirmShare()}>
						Konfirmasi
					</button>
				</div>
				<div className="claim-code">
					<form onSubmit={handleSubmit(submitClaim)}>
						<label htmlFor="code">Paste kode token dari temanmu disini</label>
						<div className="typebox">
							<input type="text" name="code" placeholder="ex: 12rT-7934-oJHf-97cV" {...register("code", { required: true })} />
							<button type="submit">
								Claim <div className="arrow" />
							</button>
						</div>
						<span className="error-msg">{lang_json[err_msg] || err_msg}</span>
					</form>
				</div>
			</div>
		</ModalCont>
	)
}

export default Share
