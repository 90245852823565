import React from "react"
import { useSelector } from "react-redux"
import "./style.scss"

const CallbackUrl = (game) => {
	const { access_token } = useSelector((state) => state.auth?.param)
	return `https://api-otrs.garena.co.id/support/callback/?access_token=${access_token}`
}

const Support = (props) => {
	const style = {
		backgroundColor: props.backgroundColor,
		color: props.textColor,
		outline: `2px solid ${props.borderColor}`,
	}

	const { text = "Contact Support", game } = props

	return (
		<a style={style} id="supportBtn" href={CallbackUrl(game)}>
			<span>{text}</span>
		</a>
	)
}

export default Support
