import React from "react"
import Confirmation from "./Confirmation"
import Congratulations from "./Congratulations"
import Error from "./Error"
import History from "./History"
import Info from "./Info"
import Intro from "./Intro"
import Mission from "./Mission"
import Share from "./Share"
import Shared from "./Shared"
import Unlocked from "./Unlocked"
import VideoShare from "./VideoShare"

const Modal = () => {
	return (
		<>
			<Intro />
			<Confirmation />
			<Congratulations />
			<Unlocked />
			<History />
			<Info />
			<Mission />
			<Share />
			<Shared />
			<VideoShare />
			<Error />
		</>
	)
}

export default Modal
