import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggle_popup } from "redux/actions/main"
import ModalCont from "./ModalCont"
import Support from "helper/component/support"

const Info = () => {
	const dispatch = useDispatch()
	const { lang_json } = useSelector((state) => state.main)

	return (
		<ModalCont tag="info" size="big">
			<button className="btn btn-close" onClick={() => dispatch(toggle_popup(false))} />
			<div className="modal__info">
				<div className="info-header">
					<span>EVENT INFO</span>
					<div className="logo" />
				</div>

				<div className="content" dangerouslySetInnerHTML={{ __html: lang_json.txt_event_info }} />

				<Support />
			</div>
		</ModalCont>
	)
}

export default Info
