import React, { Suspense, useEffect } from "react"
import { Switch, HashRouter as Router, Route } from "react-router-dom"
import { ErrorBoundary } from "@sentry/react"
import { useDispatch, useSelector } from "react-redux"

// Component
import ViewportRestrict from "helper/component/viewport"
import Loader from "helper/component/loader"
import NotFound from "helper/component/404"
import LoadingScreen from "helper/component/loadingscreen"
import Fallback from "helper/component/fallback"
import Header from "partials/Header"
import Modal from "partials/Modal"
import HistoryInfo from "partials/HistoryInfo"

// Pages
// import Home from "pages/Home"
import Tokens from "pages/Tokens"
import Exchange from "pages/Exchange"
// import Video from "pages/Video"
import { set_token } from "redux/actions/auth"
import { get_translate } from "redux/actions/main"

export const Main = () => {
	const dispatch = useDispatch()
	const { loader, spin } = useSelector((state) => state?.main)

	useEffect(() => {
		dispatch(set_token())
		dispatch(get_translate("ID"))
	}, [dispatch])

	return (
		<Router basename={process.env.REACT_APP_BASENAME} hashType="noslash">
			<div id="Main" className="main-panel">
				<ErrorBoundary fallback={Fallback}>
					<Suspense fallback={<LoadingScreen />}>
						<ViewportRestrict display={true} type="portrait" />
						<Loader loader={loader} />
						<div className={`content-container ${spin && "disabled-button"}`}>
							<Header />
							<HistoryInfo />
							<Switch>
								<Route exact path="/" render={() => <Tokens />} />
								{/* <Route path="/tokens" render={() => <Tokens />} /> */}
								<Route path="/exchange" render={() => <Exchange />} />
								{/* <Route path="/video" render={() => <Video />} /> */}
								<Route path="*" render={() => <NotFound />} />
							</Switch>
						</div>
						<Modal />
					</Suspense>
				</ErrorBoundary>
			</div>
		</Router>
	)
}

export default Main
