const main_state = {
	login: false,
	loader: false,
	lang_json: false,
	popup_type: false,
	popup_context: false,
	muteBgm: false,
	spin: false,
	result: false,
	selected_stamp: null,
	selected_reward: null,
	history: [],
	selectedExchangeIndex: false,
	disabled: false,
}

const main = (state = main_state, action) => {
	switch (action.type) {
		case "PUT_DATA":
			return { ...state, [action.key]: action.data }
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data }
		case "TOGGLE_LOADER_HASH":
			return { ...state, loader_hash: action.data }
		case "TOGGLE_POPUP":
			return { ...state, popup_type: action.tipe, popup_context: action.context }
		default:
			return state
	}
}

export default main
