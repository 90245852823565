import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggle_popup } from "redux/actions/main"
import ModalCont from "./ModalCont"
import { format } from "date-fns"

const History = () => {
	const dispatch = useDispatch()
	const tab = ["spin", "exchange", "share", "claim"]
	const [currentTab, setCurrentTab] = useState("spin")
	const { history } = useSelector((state) => state.main)
	const activity = {
		spin: "Spin Token",
		exchange: "Exchange Token",
		share: "Share Token",
		claim: "Claim Token",
	}

	return (
		<ModalCont tag="history" size="big">
			<button className="btn btn-close" onClick={() => dispatch(toggle_popup(false))} />
			<div className="modal__history">
				<div className="history-header">
					<span>HISTORY</span>
					<ul className="tab">
						{tab.map((data, index) => (
							<li key={index} className={currentTab === data ? "active" : ""} onClick={() => setCurrentTab(data)}>
								{data}
							</li>
						))}
					</ul>
					<div className="logo" />
				</div>
				<div className="content">
					<div className="col-name">
						<span>DATE</span>
						<span>ACTIVITY</span>
						<span>COST</span>
						<span>RESULT</span>
					</div>
					<ul className="history-list">
						{history
							?.filter((e) =>
								currentTab === "claim" ? e.type === "claim" || e.type === "mission_claim" : e.type === currentTab
							)
							.map((data, index) => (
								<li key={index}>
									<div className="date">{format(new Date(data.created_at * 1000), "dd.MM.yy")}</div>
									<div className="activity">{data.mission_name || activity[data.type]}</div>
									<div className="cost">
										{currentTab === "spin" ? (
											<div>
												<div className="token-ico" /> -{data.spin_cost}
											</div>
										) : currentTab === "exchange" ? (
											data.stamps.map((data, index) => (
												<div key={index}>
													<div className="stamp-ico" style={{ backgroundImage: `url(${data.stamp_image})` }} /> -
													{data.stamp_cost}
												</div>
											))
										) : currentTab === "share" ? (
											<div>
												<div className="stamp-ico" style={{ backgroundImage: `url(${data.stamps[0].stamp_image})` }} /> -1
											</div>
										) : data.type === "claim" ? (
											<div className="claim">{data.code}</div>
										) : (
											<></>
										)}
									</div>
									<div className="result">
										{currentTab === "spin" ? (
											<div>
												<div className="stamp-ico" style={{ backgroundImage: `url(${data.stamps[0].stamp_image})` }} /> +1
											</div>
										) : currentTab === "exchange" ? (
											<>
												<div>
													<div className="item-img" style={{ backgroundImage: `url(${data.item_image})` }} />
												</div>
												<span>{data.item_name}</span>
											</>
										) : currentTab === "share" ? (
											<div className="share">{data.code}</div>
										) : data.type === "claim" ? (
											<div>
												<div className="stamp-ico" style={{ backgroundImage: `url(${data.stamps[0]?.stamp_image})` }} /> +1
											</div>
										) : (
											<div>
												<div className="token-ico" /> +{data.event_token}
											</div>
										)}
									</div>
								</li>
							))}
					</ul>
				</div>
			</div>
		</ModalCont>
	)
}

export default History
