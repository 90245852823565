import React, { useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import ReactHowler from "react-howler"
import { useDispatch, useSelector } from "react-redux"
import { put_data } from "redux/actions/main"
import { getLsObject, setLsObject } from "helper"

const Header = () => {
	const dispatch = useDispatch()
	const { muteBgm, login, lang_json } = useSelector((state) => state.main)
	const [isMute, setIsMute] = useState(getLsObject("ismute") || false)
	const location = useLocation()
	const claimAvailable = login && Object.values(login.rewards)?.findIndex((dt) => dt.find((e) => e.can_claim))

	const navs = [
		{
			path: "/",
			name: lang_json.txt_tab_get_tokens,
		},
		// {
		// 	path: "/tokens",
		// 	name: lang_json.txt_tab_get_tokens,
		// },
		{
			path: "/exchange",
			name: lang_json.txt_tab_exchange,
		},
		// {
		// 	path: "/video",
		// 	name: lang_json.txt_tab_special_video,
		// },
	]

	const muteButton = () => {
		const muteLs = getLsObject("ismute")
		setIsMute(!isMute)
		setLsObject("ismute", !muteLs)
	}

	return (
		<div className="header">
			<div className="header-side">
				<button className="btn btn-audio" onClick={() => muteButton()}>
					<div className={`icon ${isMute ? "off" : "on"}`} />
					{isMute ? lang_json.txt_off : lang_json.txt_on}
				</button>
			</div>
			<div className="navigation">
				{navs.map((data, index) => (
					<NavLink
						exact={true}
						key={index}
						className={({ isActive }) => (isActive ? "active" : "")}
						to={data.path}
						onClick={() => location.pathname !== data.path && dispatch(put_data("muteBgm", false))}
					>
						<span>{data.name}</span>
						{data.path === "/exchange" && claimAvailable >= 0 && <div className="dot" />}
					</NavLink>
				))}
			</div>
			<div className="header-side player">
				<div className="token">
					<div className="token-ico" />
					<span>{login.player?.event_token}</span>
				</div>
				<span className="uid">UID: {login.player?.account_id}</span>
			</div>

			<ReactHowler
				src={`${process.env.REACT_APP_CDN}sounds/bgm.mp3`}
				playing={true}
				loop={true}
				mute={muteBgm || isMute}
			/>
		</div>
	)
}

export default Header
