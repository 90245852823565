import React from "react"
import ModalCont from "./ModalCont"
import {
	FacebookShareButton,
	TwitterShareButton,
	VKShareButton,
	WhatsappShareButton,
	FacebookMessengerShareButton,
} from "react-share"

const VideoShare = () => {
	const url = "https://youtu.be/iCw29w6YVCQ"

	return (
		<ModalCont tag="video" size="small">
			<div className="modal__video">
				<div className="title">
					<span className="main">SHARE</span>
				</div>
				<div className="content">
					<FacebookShareButton url={url} />
					<TwitterShareButton url={url} />
					<VKShareButton url={url} />
					<WhatsappShareButton url={url} />
					<FacebookMessengerShareButton url={url} />
				</div>
			</div>
		</ModalCont>
	)
}

export default VideoShare
