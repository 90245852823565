import React from "react";
import "./style.scss";

class Page404 extends React.Component {
	render() {
		return (
			<iframe className="iframe404" title="page404" src="https://oops.garena.com/404" frameBorder="0"></iframe>
		);
	}
}
export default Page404;
