import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggle_popup } from "redux/actions/main"
import ModalCont from "./ModalCont"

const Error = () => {
	const dispatch = useDispatch()
	const { err_msg, lang_json, login } = useSelector((state) => state.main)

	return (
		<ModalCont tag="error" size="small">
			<div className="modal__error">
				<div className="title">
					<span className="main">ERROR</span>
				</div>
				<div className="content">
					<span>{lang_json[err_msg] || err_msg}</span>
				</div>
				<div className="button-wrapper">
					{login && (
						<button className="btn btn-confirm" onClick={() => dispatch(toggle_popup(false))}>
							Konfirmasi
						</button>
					)}
				</div>
			</div>
		</ModalCont>
	)
}

export default Error
