import AnimatedPage from "helper/AnimatedPage"
import React, { useEffect, useState, useRef } from "react"
import ReactCardFlip from "react-card-flip"
import { useDispatch, useSelector } from "react-redux"
import { put_data, toggle_popup } from "redux/actions/main"
import ReactTooltip from "react-tooltip"

const Tokens = () => {
	const dispatch = useDispatch()
	const { spin, login, lang_json } = useSelector((state) => state.main)
	const [isFlipped, setIsFlipped] = useState(1)
	const [flipSpeed, setFlipSpeed] = useState(0.6)
	const [flipInterval, setFlipInterval] = useState(1000)
	const counterRef = useRef(1)

	useEffect(() => {
		counterRef.current = isFlipped
	})

	useEffect(() => {
		ReactTooltip.rebuild()
	})

	useEffect(() => {
		let flipping =
			flipInterval &&
			setInterval(() => {
				if (counterRef.current === 9) {
					setIsFlipped(1)
				} else {
					setIsFlipped(counterRef.current + 1)
				}
			}, flipInterval)

		if (spin) {
			dispatch(toggle_popup(false))
			setFlipSpeed(0.3)
			setFlipInterval(200)

			setTimeout(() => {
				dispatch(put_data("spin", false))
				setFlipSpeed(0.6)
				setFlipInterval(1000)
				dispatch(toggle_popup("congratulations", "token"))
			}, 2000)
		}

		return () => {
			clearInterval(flipping)
		}
	}, [spin, flipInterval, dispatch])

	return (
		<AnimatedPage>
			<div className="page__tokens">
				<div className="overlays-left" />
				<div className="center-content">
					<ReactCardFlip
						isFlipped={isFlipped}
						infinite={true}
						flipSpeedBackToFront={flipSpeed}
						flipSpeedFrontToBack={flipSpeed}
					>
						<div className={`char char-${isFlipped}`} />
						<div className={`char char-${isFlipped}`} />
					</ReactCardFlip>
					<div className="action">
						<button
							className={`btn btn-spin ${
								(spin || login?.player?.event_token < login?.common_config?.spin_cost) && "disabled"
							}`}
							onClick={() => dispatch(toggle_popup("confirmation", "spin"))}
						>
							{lang_json.txt_btn_spin_token} <div className="token-ico" /> <span>{login.common_config?.spin_cost}</span>
						</button>
						<button className="btn btn-mission" onClick={() => dispatch(toggle_popup("mission"))}>
							{lang_json.txt_get_token_here} &gt;
						</button>
					</div>
				</div>
				<div className="token-container">
					<div className="stamps">
						{login.stamps?.map((data, index) => (
							<div
								key={index}
								style={
									data.owned > 0
										? {
												backgroundImage: `url(${data.stamp_image})`,
										  }
										: {}
								}
							>
								{index === 9 && (
									<div
										className="tooltip"
										data-for="token"
										data-tip={lang_json.txt_how_to_get_special_token}
										data-offset="{'top' : -8}"
									>
										<div className="dot" />
									</div>
								)}
								{data.owned > 0 && <div className="count">{data.owned}</div>}
							</div>
						))}
					</div>
					<div className="action">
						<span>{lang_json.txt_share_token_info}</span>
						<button className="btn btn-share" onClick={() => dispatch(toggle_popup("share"))}>
							{lang_json.txt_btn_share_token} <div className="arrow" />
						</button>
					</div>
				</div>
			</div>
			<ReactTooltip id="token" effect="solid" place="top" isCapture={true} className="tooltip" />
		</AnimatedPage>
	)
}

export default Tokens
