import React from "react"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import { useDispatch, useSelector } from "react-redux"
import { toggle_popup } from "redux/actions/main"
import "animate.css"

const ModalCont = ({ children, tag, size }) => {
	const dispatch = useDispatch()
	const { popup_type } = useSelector((state) => state.main)

	return (
		<Modal
			open={popup_type === tag}
			onClose={() => dispatch(toggle_popup(false))}
			center
			closeOnOverlayClick={tag === "video"}
			closeOnEsc={false}
			showCloseIcon={false}
			classNames={{
				overlay: `customOverlay ${tag}`,
				modal: `customModal ${size} animate__animated animate__faster ${
					tag === "video" ? (popup_type ? "animate__slideInUp" : "animate__slideOutDown") : ""
				}`,
			}}
		>
			{children}
		</Modal>
	)
}

export default ModalCont
