import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { toggle_popup } from "redux/actions/main"
import ModalCont from "./ModalCont"

const Unlocked = () => {
	const dispatch = useDispatch()
	const { result } = useSelector((state) => state.main)

	const closeModal = () => {
		dispatch(toggle_popup(false))
	}

	return (
		<ModalCont tag="unlocked" size="small">
			<div className="modal__unlocked">
				<div className="title">
					<span className="main">SPECIAL VIDEO UNLOCKED!</span>
					<span className="sub">Silahkan tonton video khusus buat kamu</span>
				</div>
				<div className="content">
					<div className="img" style={{ backgroundImage: `url(${result?.video_thumbnail})` }} />
					<span className="name">
						{result?.member_name} {result?.member_second_name && `& ${result?.member_second_name}`}
					</span>
				</div>
				<div className="button-wrapper">
					<button className="btn btn-back" onClick={() => closeModal()}>
						Back
					</button>
					<Link to="/video" className="btn btn-watch" onClick={() => closeModal()}>
						Watch <div className="arrow" />
					</Link>
				</div>
			</div>
		</ModalCont>
	)
}

export default Unlocked
